import { useCallback, useEffect } from 'react';

import {
  Link,
  useLocation,
  useSearchParams,
  useSubmit,
} from '@remix-run/react';

import clsx from 'clsx';
import { motion } from 'framer-motion';
import { cloneDeep as _cloneDeep, set as _set } from 'lodash-es';

import { CheckIcon } from '@heroicons/react/24/solid';

import type { SourceObjectPath } from '~/utils';
import type { GenericObject } from './Form/ObjectsArrayInput';

import type { Source } from '~/models';

import { useDiffableItem } from '~/hooks';
import { useMultiStepFormWrapperState, useUser } from '~/providers';

import { sourceTypeDict, fadeInMoveUp } from '~/utils';

import Button from './Button';
import { Field } from './Form';
import { FormCategoryHeader } from './FormCategoryHeader';

type SourceTitleInputProps = {
  header?: boolean;
  placeholder?: string;
} & JSX.IntrinsicElements['input'];
export const SourceTitleInput = ({
  type,
  header = false,
  placeholder,
  ...props
}: SourceTitleInputProps) =>
  type === 'hidden' ? null : (
    <div className="flex flex-col gap-2">
      {header && (
        <FormCategoryHeader
          title="Index Title"
          text="Give your index a title."
        />
      )}

      <Field
        labelClassName="font-medium text-sm"
        className="overflow-ellipsis"
        name="title"
        label={header ? '' : 'Give your index a title'}
        placeholder={placeholder ? placeholder : 'New index'}
        {...props}
      />
    </div>
  );

type SourceFormProps = {
  source: Source;
  action: string;
  returnTo: string;
  className?: string;
  isCreate?: boolean;
  title?: string;
  internal?: boolean;
  triggerSubmit?: boolean;
};

type UpdateObj = {
  [key in SourceObjectPath]?: unknown;
};

const SourceForm = ({
  source: originalSource,
  action,
  returnTo,
  className,
  isCreate = false,
  title,
  internal,
  triggerSubmit = false,
}: SourceFormProps) => {
  const {
    user: { roles },
    account: { features, is_onboarding },
  } = useUser();
  const [searchParams] = useSearchParams();
  const instanceId = searchParams.get('instance_id');
  const location = useLocation();

  const submit = useSubmit();

  const { currentStep, setDisableNextStep } = useMultiStepFormWrapperState();

  const hasConnectionSettings =
    originalSource.type !== 'manual_upload' &&
    originalSource.type !== 'instant_answers';

  const {
    item: source,
    setItem: setSource,
    hasChanges,
  } = useDiffableItem(
    originalSource,
    (s1, s2) => JSON.stringify(s1) === JSON.stringify(s2),
  );

  useEffect(() => {
    if (
      currentStep === 4 &&
      source.configuration &&
      source.type === 'web_scrape'
    ) {
      setDisableNextStep(
        source.configuration.entrypoint_urls.length === 0 ||
          source.configuration.allowed_domains.length === 0 ||
          source.configuration.url_filters.length === 0,
      );
    }
  }, [currentStep, setDisableNextStep, source.configuration, source.type]);

  const handleSave = useCallback(() => {
    const sourceToSubmit = _cloneDeep(source);
    if (
      isCreate &&
      typeof title !== 'undefined' &&
      typeof internal !== 'undefined'
    ) {
      sourceToSubmit.title = title;
      sourceToSubmit.internal = internal;
    }

    submit(
      {
        json: JSON.stringify({
          ...sourceToSubmit,
          sync_configuration: {
            full_scrape_interval:
              sourceToSubmit.sync_configuration?.full_scrape_interval ||
              'never',
            partial_scrape_interval:
              sourceToSubmit.sync_configuration?.partial_scrape_interval ||
              'never',
            pod_size: originalSource.sync_configuration?.pod_size ?? 'small',
          },
        }),
        redirectTo: location.pathname.split('/settings')[0],
      },
      { method: 'post', action },
    );
  }, [
    source,
    isCreate,
    title,
    internal,
    submit,
    originalSource.sync_configuration,
    location.pathname,
    action,
  ]);

  useEffect(() => {
    if (triggerSubmit) {
      handleSave();
    }
  }, [triggerSubmit, handleSave]);

  const handleOnChange = useCallback(
    (obj: UpdateObj) => {
      if (Object.keys(obj).length > 0) {
        setSource((prev) => {
          const updatedSource = _cloneDeep(prev);
          Object.keys(obj).forEach((key: string) => {
            if (
              !key.includes('[]') &&
              typeof obj[key as SourceObjectPath] !== 'undefined'
            ) {
              let updateKey = key;

              if (
                key !== 'sync' &&
                key !== 'title' &&
                key !== 'sync_configuration.full_scrape_interval' &&
                key !== 'sync_configuration.partial_scrape_interval'
              ) {
                updateKey = `configuration.${key}`;
              }

              let newValue = obj[key as SourceObjectPath];
              if (newValue === 'true' || newValue === 'false') {
                newValue = newValue === 'true';
              }

              _set(updatedSource, updateKey, newValue);
            }
          });

          return updatedSource;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSource],
  );

  // const overrideValues = useMemo(() => {
  //   if (
  //     source.type !== 'web_scrape' ||
  //     source.configuration?.entrypoint_urls.length === 0
  //   )
  //     return {};

  //   const values = source.configuration?.entrypoint_urls as string[];
  //   return {
  //     allowed_domains: [
  //       ...values.map((value) => new URL(correctURL(value)).hostname),
  //       // ...source.configuration?.allowed_domains?.filter((value) =>
  //       //   values.includes(value),
  //       // ),
  //     ],
  //     url_filters: values.map((value) => `^${correctURL(value)}.*$`),
  //   };
  // }, [source]);
  // const previousOverride = usePreviousValue(overrideValues);

  return (
    <div
      className="h-full w-full"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={(e: React.ChangeEvent<HTMLFormElement>) =>
        handleOnChange({
          [e.target.name as SourceObjectPath]:
            e.target.type === 'checkbox' ? e.target.checked : e.target.value,
        })
      }
    >
      <div
        className={clsx(
          'mx-auto flex flex-col items-center gap-6 pb-14',
          className,
        )}
      >
        {instanceId && (
          <input type="hidden" name="instance_id" value={instanceId} />
        )}

        <input type="hidden" name="id" value={source.id} />
        <input type="hidden" name="type" value={source.type} />
        <input
          type="hidden"
          name="internal"
          value={source.internal.toString()}
        />
        <input
          type="hidden"
          name="knowledge_base_id"
          value={source.knowledge_base_id}
        />
        <input type="hidden" name="redirectTo" value={returnTo} />

        {!hasConnectionSettings && !isCreate && (
          <FormCategoryHeader
            title="Change the name of this index"
            text={`For ${
              sourceTypeDict[originalSource.type].name
            } index you can only change the name`}
            className="mb-8 mt-52 items-center"
          />
        )}

        <div className="w-full">
          <SourceTitleInput
            defaultValue={originalSource.title}
            type={isCreate ? 'hidden' : 'text'}
          />
        </div>

        {hasConnectionSettings && (
          <motion.div
            key={originalSource.type}
            className="mx-auto flex w-full flex-col items-center gap-6 pb-14"
            {...(isCreate ? { fadeInMoveUp } : {})}
          >
            {!isCreate && (
              <FormCategoryHeader
                title="Connection Settings"
                text="Type in the specified connection settings to connect to this index."
              />
            )}

            {sourceTypeDict[originalSource.type]?.fields.map(
              ({
                name,
                label,
                placeholder,
                inputType,
                options,
                requiredRoles,
                defaultValue: sourceDefault,
                necessity,
                description,
                fields,
              }) => {
                const cleanName = name.replace('[]', '');
                let isDisabled = false;

                if (
                  // @ts-expect-error
                  originalSource.configuration[cleanName] === undefined &&
                  inputType === 'checkbox'
                ) {
                  // @ts-expect-error
                  originalSource.configuration[cleanName] = false;
                }

                const kind =
                  inputType === 'select'
                    ? 'select'
                    : inputType === 'checkbox'
                      ? 'checkbox'
                      : inputType === 'array'
                        ? 'array'
                        : inputType === 'objectArray'
                          ? 'objectArray'
                          : 'input';

                let defaultValue =
                  // @ts-expect-error
                  originalSource.configuration[cleanName];

                if (cleanName === 'sync_configuration.full_scrape_interval') {
                  defaultValue = source['sync_configuration']
                    ? source['sync_configuration']['full_scrape_interval']
                    : 'never';
                  isDisabled = is_onboarding;
                }

                if (
                  cleanName === 'sync_configuration.partial_scrape_interval'
                ) {
                  defaultValue = source['sync_configuration']
                    ? source['sync_configuration']['partial_scrape_interval']
                    : 'never';
                  isDisabled = is_onboarding;
                }

                if (cleanName === 'comment') {
                  defaultValue = source['comment'];
                }

                if (kind === 'select') {
                  const option = options?.find(
                    ({ value }) => value === defaultValue,
                  );

                  if (!option) {
                    defaultValue = undefined;
                  } else {
                    defaultValue = {
                      label: option.label,
                      value: defaultValue,
                    };
                  }
                }

                const defaultChecked =
                  // @ts-expect-error
                  originalSource.configuration[cleanName];

                return (
                  //@ts-ignore
                  <Field
                    kind={kind}
                    key={name}
                    name={cleanName}
                    label={label}
                    placeholder={placeholder}
                    type={inputType}
                    disabled={isDisabled}
                    {...(kind !== 'checkbox' && {
                      defaultValue,
                    })}
                    {...(kind === 'checkbox' && {
                      defaultChecked,
                    })}
                    //@ts-ignore
                    options={options}
                    className={clsx({
                      hidden:
                        requiredRoles &&
                        requiredRoles.filter((value) => roles.includes(value))
                          .length === 0,
                    })}
                    {...(kind === 'select' && {
                      onChange: ({ value }: { value: string }) =>
                        handleOnChange({
                          [name]: value,
                        }),
                      isDisabled,
                    })}
                    {...(kind === 'array' && {
                      onBlurNormaliseURL: cleanName === 'entrypoint_urls',
                      onChange: (updateObj: string[]) => {
                        handleOnChange({
                          [cleanName]: updateObj.filter(
                            (value) => value !== '',
                          ),
                        });
                      },
                    })}
                    {...(kind === 'objectArray' && {
                      onChange: (
                        updateObj:
                          | GenericObject[]
                          | { value: GenericObject; index: number },
                      ) => {
                        if (Array.isArray(updateObj)) {
                          handleOnChange({
                            [cleanName]: updateObj,
                          });
                        } else {
                          handleOnChange({
                            [`${cleanName}[${updateObj.index}]`]:
                              updateObj.value,
                          });
                        }
                      },
                      fields,
                    })}
                    necessity={necessity ?? 'normal'}
                    description={
                      cleanName === 'sync' && !features.daily_sync?.amount
                        ? '"Daily" sync not purchased!'
                        : description
                    }
                  />
                );
              },
            )}
          </motion.div>
        )}
      </div>

      {!isCreate && (
        <div
          className={clsx(
            'absolute bottom-3 left-[51%] flex -translate-x-1/2 justify-center gap-4 p-4 transition-[inset] delay-150 duration-300',
          )}
        >
          <Link to={returnTo} replace>
            <Button className="px-6">Cancel</Button>
          </Link>

          <Button
            Icon={CheckIcon}
            className="px-6"
            variant="primary"
            onClick={handleSave}
            disabled={
              (!hasChanges && !isCreate) ||
              (originalSource.title === '' && title === '') ||
              (source.type === 'web_scrape' &&
                (source.configuration?.entrypoint_urls.length === 0 ||
                  source.configuration?.allowed_domains.length === 0 ||
                  source.configuration?.url_filters.length === 0))
            }
          >
            {isCreate ? 'Create' : 'Save'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SourceForm;
